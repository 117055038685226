import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: () => import('../views/Home.vue')
  },
  {
    path: '/work',
    name: 'Work',
    component: () => import('../views/Work.vue')
  },
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: '/skill',
    name: 'Skill',
    component: () => import('../views/Skill.vue')
  },
  // works
  {
    path: '/zoe',
    name: 'Zoe',
    component: () => import('../views/data/Zoe.vue')
  },
  {
    path: '/hellochat',
    name: 'HelloChat',
    component: () => import('../views/data/HelloChat.vue')
  },
  {
    path: '/hellopass',
    name: 'HelloPass',
    component: () => import('../views/data/HelloPass.vue')
  },
  // {
  //   path: '/julian-portfolio',
  //   name: 'julian',
  //   component: () => import('../views/data/Julian.vue')
  // },
  {
    path: '/miniwiki',
    name: 'miniwiki',
    component: () => import('../views/data/Miniwiki.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
  scrollBehavior() {
    return {x: 0, y: 0}
}
})

export default router
