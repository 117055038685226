<template>
  <div id="app">
    <router-view/>
  </div>
</template>

<script>
export default {
  data() {
    return {
      //
    };
  },
  mounted() {
    document.title = 'Julian ｜ A passionate full-stack designer'
  }
}
</script>

<style>
@import "~bulma/css/bulma.css";
@import "assets/css/main.css";
</style>
