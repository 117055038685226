import Vue from 'vue'
import App from './App.vue'
import './registerServiceWorker'
import router from './router'
import 'animate.css/animate.min.css'
import BackToTop from 'vue-backtotop'
import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
  config: { id: "UA-123599412-1" }
});

Vue.use(BackToTop)

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')
